<!-- 更换货号 -->
<template>
  <a-modal
    width="500px"
    v-model:visible="visible"
    :title="$t($enumLangkey('productErrorDisposeType', productErrorDisposeTypeEnum.changeSeSku))"
    :mask-closable="false"
    :closable="false"
    :centered="true"
    @ok="handleConfirm"
    @cancel="handleCancel"
  >
    <a-row type="flex" justify="center">
      <a-col>
        <SearchProduct ref="searchProductRef" />
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, ref, toRefs } from 'vue';
import { Col, Modal, Row, message } from 'ant-design-vue';
import SearchProduct from '@/views/components/SearchProduct.vue';
import { productErrorDisposeType as productErrorDisposeTypeEnum } from '@/enum/enum.json';
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  expose: ['open'],
  emits: ['confirm', 'cancel'],
  components: {
    AModal: Modal,
    ARow: Row,
    ACol: Col,
    SearchProduct,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const searchProductRef = ref(null);

    const state = reactive({
      visible: false,
      productInfo: null,
      formState: {
        targetProductId: null,
      },
    });

    const handleConfirm = () => {
      let id = searchProductRef.value.selectedProductId;
      if (!id) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.product_sku')]));
        return;
      }
      emit('confirm', { id, record: state.productInfo });
      close();
    }

    const handleCancel = () => {
      emit('cancel', { record: state.productInfo });
      close();
    }

    const open = (record) => {
      state.visible = true;
      state.productInfo = JSON.parse(JSON.stringify(record));
    }

    const close = () => {
      state.visible = false;
    }

    return {
      ...toRefs(state),
      productErrorDisposeTypeEnum,
      searchProductRef,
      handleConfirm,
      handleCancel,
      open,
      close,
    }
  }
})
</script>

<style scoped>

</style>