<!-- 产品统计 -->
<template>
  <a-modal
    width="auto"
    v-model:visible="visible"
    :title="$t('common.product_statistics')"
    :centered="true"
    :mask-closable="false"
    :cancel-text="$t('common.close')"
    :ok-button-props="{ style: { display: 'none' } }"
  >
    <a-table
      :columns="columns"
      :loading="tableData.loading"
      :data-source="tableData.tableList"
      :scroll="{ x: 500, y: 400 }"
      :pagination="false"
      :row-key="(record, index) => index"
      size="small"
    >
      <template #count="{ record }">{{ record.count }} Unit</template>
      <template #inWarehouseCount="{ record }">{{ record.inWarehouseCount }} Unit</template>
      <template #footer>
        <a-row type="flex" justify="end">
          <a-col flex="0 0 120px">{{ $t('warehouse.total_box_count') }}: {{ tableData.totalBoxes }}</a-col>
          <a-col flex="0 0 120px">{{ $t('common.total_product') }}: {{ tableData.totalProducts }}</a-col>
          <a-col flex="0 0 120px">{{ $t('warehouse.total_inbound_receipt_quantity') }}: {{ tableData.piTotal }}</a-col>
        </a-row>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue'
import { Col, Modal, Row, Table } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { getProductStatistics } from '@/api/modules/consignment/inbound.js';

export default defineComponent({
  components: {
    AModal: Modal,
    ATable: Table,
    ARow: Row,
    ACol: Col,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      visible: false,
      searchState: {
        planId: null,
        warehouseType: null,
      },
      tableData: {
        loading: false,
        tableList: [],
        totalBoxes: 0,
        totalProducts: 0,
        piTotal: 0,
      },
    });

    const columns = [
      {
        dataIndex: 'productNo',
        title: () => vueI18n.t('warehouse.product_no'),
        width: 200,
      },
      {
        dataIndex: 'sesku',
        title: 'SESKU',
        width: 200,
      },
      {
        dataIndex: 'boxCount',
        title: () => vueI18n.t('warehouse.box_amount'),
        width: 120,
      },
      {
        title: () => vueI18n.t('logistics.count'),
        width: 120,
        slots: {
          customRender: 'count'
        }
      },
      {
        title: () => vueI18n.t('warehouse.received_quantity'),
        width: 120,
        slots: {
          customRender: 'inWarehouseCount'
        }
      },
    ];

    const getList = () => {
      state.tableData.loading = true;
      const data = Object.assign({}, state.searchState);
      getProductStatistics(data).then(({ result }) => {
        if (result) {
          let { total, pTotal, piTotal, products } = result;
          state.tableData.tableList = products;
          state.tableData.totalBoxes = total;
          state.tableData.totalProducts = pTotal;
          state.tableData.piTotal = piTotal;
        } else {
          state.tableData.tableList = [];
        }
      }).catch(() => {}).finally(() => {
        state.tableData.loading = false;
      });
    }

    const open = ({ planId, warehouseType }) => {
      state.visible = true;
      state.searchState.planId = planId;
      state.searchState.warehouseType = warehouseType;
      getList();
    }

    const close = () => {
      state.visible = false;
    }

    return {
      ...toRefs(state),
      columns,
      open,
      close,
    }
  }
})
</script>

<style scoped>

</style>