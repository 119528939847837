<!-- 请求调查 -->
<template>
  <a-modal
    width="700px"
    v-model:visible="visible"
    :title="$t('warehouse.request_to_investigate')"
    :mask-closable="false"
    :centered="true"
    :closable="false"
    @ok="handleConfirm"
    @cancel="handleCancel"
  >
    <a-row :gutter="[16, 8]">
      <a-col :span="12">{{ $t('warehouse.box_no') }}: {{ modalInfo.boxNo }}</a-col>
      <a-col :span="12">{{ $t('warehouse.in_warehouse_plan_no') }}: {{ modalInfo.planNo }}</a-col>
      <a-col :span="8">{{ $t('warehouse.logistics_way') }}: {{ $t($enumLangkey('logisticsWay', modalInfo.logisticsType)) }}</a-col>
      <a-col :span="8">{{ $t('warehouse.delivery_way') }}: {{$t($enumLangkey('deliveryWay', modalInfo.deliveryType)) }}</a-col>
      <a-col :span="8">{{ $t('warehouse.tracking_no') }}: {{ modalInfo.trackNo || '-' }}</a-col>
    </a-row>
    <div class="mt-3 mb-1">{{ $t('warehouse.apply_content') }}</div>
    <a-textarea v-model:value="formState.content" :rows="3" allow-clear></a-textarea>
    <div class="mt-3 mb-3">
      {{ $t('warehouse.upload_prove_file') }}
      <a-upload
        name="template"
        list-type="text"
        accept=".jpeg,.jpg,.png,.pdf"
        :show-upload-list="false"
        :customRequest="handleUpload"
      >
        <a-button :disabled="formState.imgUrls.length >= 3">
          <loading-outlined v-if="uploadLoading"></loading-outlined>
          <upload-outlined v-else></upload-outlined>
          {{ $t("warehouse.select_import_file") }}
        </a-button>
      </a-upload>
    </div>
    <a-row :gutter="[16, 16]">
      <a-col v-for="(item, index) in formState.imgUrls" :key="index">
        <!-- <div class="table-list-img-common">
          <c-image
            :src="item"
            :thumbWidth="600"
            :thumbHeight="600"
          />
        </div> -->
        <CFileDisplay :fileUrl="item"></CFileDisplay>
        <a-row type="flex" justify="center">
          <a-col>
            <a-tooltip placement="right" :title="$t('common.delete')">
              <a-button type="link" @click="handleDelete(index)" style="text-align: center;">
                <DeleteOutlined />
              </a-button>
            </a-tooltip>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import { Button, Card, Col, Input, Modal, Row, Tooltip, Upload, message } from 'ant-design-vue';
import CImage from '@/views/components/CImage.vue';
import CFileDisplay from '@/views/components/CFileDisplay.vue';
import { setFormStateValue } from '@/utils/general.js';
import { uploadSupportingDocuments } from '@/api/modules/consignment/inbound.js';
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  expose: ['open'],
  emits: ['confirm', 'cancel'],
  components: {
    CFileDisplay,
    AModal: Modal,
    ARow: Row,
    ACol: Col,
    ATextarea: Input.TextArea,
    ACard: Card,
    AUpload: Upload,
    ATooltip: Tooltip,
    AButton: Button,
    CImage,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      visible: false,
      modalInfo: {
        detailId: null,
        boxNo: null,
        planNo: null,
        logisticsType: null,
        deliveryType: null,
        trackNo: null,
      },
      uploadLoading: false,
      formState: {
        content: null,
        imgUrls: [],
      }
    });

    const handleDelete = (index) => {
      state.formState.imgUrls.splice(index, 1);
    }

    const handleUpload = (file) => {
      state.uploadLoading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      parameter.append("functionModule", 3);
      uploadSupportingDocuments(parameter).then(({ result }) => {
        if (Array.isArray(result)) {
          state.formState.imgUrls.push(result[0].url);
        }
      }).catch(() => {}).finally(() => {
        state.uploadLoading = false;
      })
    }

    const handleConfirm = () => {
      const requestInvestigation = Object.assign({}, state.formState);
      if (!requestInvestigation.content) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.apply_content')]));
        return;
      }
      if (!requestInvestigation.imgUrls.length) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.upload_prove_file')]));
        return;
      }
      emit('confirm', { detailId: state.modalInfo.detailId, requestInvestigation });
      close();
    }

    const handleCancel = () => {
      emit('cancel', { detailId: state.modalInfo.detailId });
    }

    const open = (pageState, detailId, boxNo, trackNo, requestInvestigation = null) => {
      state.visible = true;
      setFormStateValue(state.modalInfo, pageState);
      state.modalInfo.detailId = detailId;
      state.modalInfo.boxNo = boxNo;
      state.modalInfo.trackNo = trackNo;
      if (requestInvestigation) {
        setFormStateValue(state.formState, requestInvestigation);
      } else {
        // 清空
        state.formState.content = null;
        state.formState.imgUrls = [];
      }
    }

    const close = () => {
      state.visible = false;
    }

    return {
      ...toRefs(state),
      handleDelete,
      handleUpload,
      handleConfirm,
      handleCancel,
      open,
      close,
    }
  }
})
</script>

<style scoped>

</style>