<template>
  <Content>
    <template #contentTitle>{{ $t($enumLangkey("warehouseType", pageState.warehouseType)) }}-{{ $t('menu.consignment_inbound_detail') }}</template>
    <template #contentBody>
      <a-spin :spinning="loading">
        <a-row :gutter="[8, 8]" type="flex" align="middle">
          <a-col :span="24" :lg="16" :xl="18">
            <a-row :gutter="[8, 8]">
              <a-col :span="24" :lg="12" :xl="8">
                {{ $t('warehouse.in_warehouse_plan_no') }}: {{ pageState.planNo || '-' }}
                <template v-if="pageState.hasException">
                  <a-tag class="ml-2" v-if="pageState.inWarehouseStatus === inWarehouseStatusEnum.complete || pageState.inWarehouseStatus === inWarehouseStatusEnum.cancel">{{ $t('common.exception') }}</a-tag>
                  <a-tag class="ml-2" color="error" v-else>{{ $t('common.exception') }}</a-tag>
                </template>
              </a-col>
              <a-col :span="24" :lg="12" :xl="8">{{ $t('warehouse.in_plan_name') }}: {{ pageState.planName || '-' }}</a-col>
              <a-col :span="24" :lg="12" :xl="8" v-if="pageState.relation">
                {{ $t('warehouse.source_related_doc_no') }}: <CPlanNumberItem :no="pageState.relation"></CPlanNumberItem>
              </a-col>
              <a-col :span="24" :lg="12" :xl="8" v-else-if="pageState.toRelationPlanNo">
                {{ $t('warehouse.target_related_doc_no') }}: <CPlanNumberItem :no="pageState.toRelationPlanNo"></CPlanNumberItem>
              </a-col>
              <a-col :span="24" :lg="12" :xl="8" v-else>
                {{ $t('warehouse.relation_no') }}: -
              </a-col>
              <a-col :span="24" :lg="12" :xl="8">
                {{ $t('warehouse.creation_time') }}: {{ $filters.utcToCurrentTime(pageState.creationTime) }}
              </a-col>
              <a-col :span="24" :lg="12" :xl="8">
                {{ $t('warehouse.update_time') }}: {{ $filters.utcToCurrentTime(pageState.lastModificationTime) }}
              </a-col>
              <a-col :span="24" :lg="12" :xl="8">
                {{ $t('warehouse.in_warehouse_type') }}: {{ $t($enumLangkey("inWarehouseType", pageState.inWarehouseType)) }}
              </a-col>
              <a-col :span="24" :lg="12" :xl="8">
                {{ $t('warehouse.in_target_warehouse') }}: 
                <span v-if="pageState.to">{{ pageState.to.no }}({{ pageState.to.name }})</span>
                <span v-else>{{ '-' }}</span>
              </a-col>
              <a-col :span="24" :lg="12" :xl="16">
                {{ $t('warehouse.warehouse_address') }}: 
                <template v-if="pageState.to">
                  <span>{{ getFullAddress(pageState.to) }}</span>
                  <span class="ml-1">({{ $t("logistics.contacts") }}: {{ getLinkman(pageState.to) }})</span>
                </template>
                <span v-else>{{ '-' }}</span>
              </a-col>
              <a-col :span="24" :lg="12" :xl="8">
                {{ $t('warehouse.originate') }}: 
                <span v-if="pageState.from">{{ pageState.from.name || '-' }}</span>
                <span v-else>{{ '-' }}</span>
              </a-col>
              <a-col :span="24" :lg="12" :xl="16">
                {{ $t('warehouse.from_product_address') }}: 
                <template v-if="pageState.from">
                  <span>{{ getFullAddress(pageState.from) }}</span>
                  <span class="ml-1" v-if="pageState.from.isWarehouse">({{ $t("logistics.contacts") }}: {{ getLinkman(pageState.from) }})</span>
                </template>
                <span v-else>{{ '-' }}</span>
              </a-col>
              <template v-if="pageState.total">
                <a-col :span="24" :lg="12" :xl="8">
                  {{ $t('warehouse.planned_quantity') }}: {{ pageState.total.plan?.totalWarehouseCount ?? '-' }} Unit
                </a-col>
                <a-col :span="24" :lg="12" :xl="16">
                  {{ $t('warehouse.planned_box_quantity') }}: {{ pageState.total.plan?.totalContainerBoxCount ?? '-' }} {{ $t('warehouse.box') }}
                </a-col>
              </template>
            </a-row>
          </a-col>
          <a-col :span="24" :lg="8" :xl="6">
            <div style="text-align: center;">{{ $t('warehouse.plan_status') }}</div>
            <div style="text-align: center;">
              <span v-if="inWarehouseStatusEnum.cancel" class="text-success">{{ $t($enumLangkey('inWarehouseStatus', pageState.inWarehouseStatus)) }}</span>
              <span v-else style="color: #aaa">{{ $t($enumLangkey('inWarehouseStatus', pageState.inWarehouseStatus)) }}</span>
            </div>
          </a-col>
        </a-row>
        <a-table
          style="overflow-x: auto;"
          class="mt-3"
          :columns="columns"
          :data-source="boxList"
          size="small"
          :expandIconAsCell="false"
          :expandIconColumnIndex="2"
          :pagination="pagination"
          :row-key="(record) => record.detailId"
          @change="(page) => pagination.current = page.current"
        >
          <template #serialNumber="{ index }">
            {{ ((pagination.current - 1) * pagination.pageSize) + (index + 1) }}
          </template>
          <template #boxNoTitle>
            <div>{{ $t('warehouse.box_no') }}</div>
            <div>{{ $t('common.user_defined_box_no') }}</div>
          </template>
          <template #boxNo="{ record }">
            <div>
              {{ record.box.boxNo || '-' }}
              <CTagMixedOrSingle :productKindCount="record.box.container.productKindCount * 1"></CTagMixedOrSingle>
            </div>
            <div>
              <UserDefinedBoxNo :boxId="record.box.whBoxId" :userDefinedBoxNo="record.box.selfBoxNo"></UserDefinedBoxNo>
            </div>
          </template>
          <template #expandIcon="{ expanded, record, onExpand }">
            <CHaveProductInformation :disabled="!record.box.container?.hasRelationProduct" :open="expanded" @click="e => onExpand(record, e)" />
          </template>
          <template #encasementSize="{ record }">
            <span v-if="record.box.container">{{ record.box.container.containerLength }}x{{ record.box.container.containerWidth }}x{{ record.box.container.containerHeight }} cm</span>
            <span v-else>-</span>
          </template>
          <template #encasementWeight="{ record }">
            <span v-if="record.box.container">{{ gToKg(record.box.container.containerWeight) }} kg</span>
            <span v-else>-</span>
          </template>
          <template #encasementCount="{ record }">
            <div v-if="getBoxProductCount(record.box) != record.box.container?.containerCount">
               <a-typography-text
                delete
                class="mr-1"
              >
                {{record.box.container.containerCount}} Unit
              </a-typography-text>
              {{getBoxProductCount(record.box)}} Unit
            </div>
            <div v-else>
                {{ record.box.container ? record.box.container.containerCount : '-' }} Unit
            </div>
          </template>
          <template #labelDispose="{ record }">
            {{ $t($enumLangkey('labelDispose', record.productLabelProcessing)) }}
          </template>
          <template #inWarehouseStatus="{ record }">
            {{ $t($enumLangkey('inWarehouseStatus', record.inWarehouseStatus)) }}
          </template>
          <template #inWarehouseCount="{ record }">
           {{record.box.inWarehouseCount ? record.box.inWarehouseCount+ 'Unit' :"-"}}
          </template>
          <template #errorState="{ record }">
            <div class="text-error" v-if="record.box.exception.currentErrorType">
              <a-row type="flex"  align="middle">
                <a-col>
                  {{ $t($enumLangkey('inWarehouseOperationErrorType', record.box.exception.currentErrorType)) }}
                </a-col>
                <a-col>
                  <div v-if="record.box.exception.currentErrorType == inWarehouseOperationErrorTypeEnum.errorProduct">
                    <a-popover v-if="record.box?.exception?.errorProduct?.errorProductUrls && record.box?.exception?.errorProduct?.errorProductUrls?.length > 0" :getPopupContainer="trigger => trigger.parentNode">
                      <template #content>
                        <a-space>
                          <div v-for="(imgItem, index) in record.box?.exception?.errorProduct?.errorProductUrls"
                              class="table-list-img-common"
                              :key="index">
                            <c-image :src="imgItem" />
                          </div>
                        </a-space>
                      </template>
                      <span class="text-primary" style="font-size:20px">
                        <FileImageOutlined />
                      </span>
                    </a-popover>
                  </div>
                </a-col>
              </a-row>
            </div>
            <span class="text-error" v-else-if="record.box.exception.boxErrorType">
              {{ $t($enumLangkey('inWarehouseOperationErrorType', record.box.exception.boxErrorType)) }} 
            </span>
            <span v-else>-</span>
          </template>
          <template #needOperate="{ record }">
            <!-- 需要操作 -->
            <template v-if="record.box.exception.currentErrorType">
              <template v-if="record.box.exception.currentErrorType">
                <!-- record.box.exception.currentErrorType == 1 (接收超时) : 显示产品漏发 和请求调查-->
                <a-select
                  v-if=" record.box.exception.currentErrorType == inWarehouseOperationErrorTypeEnum.receiveTimeout "
                  v-model:value="record.box.exception.timeOut.inWarehouseErrorCustomerType"
                  :placeholder="$t('common.please_select')"
                  style="width: 150px;"
                  @change="handleErrorDisposeChange(record)"
                >
                  <a-select-option :value="productErrorDisposeTypeEnum.omitSend">{{ $t($enumLangkey('productErrorDisposeType', productErrorDisposeTypeEnum.omitSend)) }}</a-select-option>
                  <a-select-option :value="productErrorDisposeTypeEnum.requestInvestigate">{{ $t($enumLangkey('productErrorDisposeType', productErrorDisposeTypeEnum.requestInvestigate)) }}</a-select-option>
                </a-select>

                <!-- record.box.exception.currentErrorType == 2 (错误商品): 放弃入库并销毁和更改箱内信息-->
                  <a-select
                  v-if=" record.box.exception.currentErrorType == inWarehouseOperationErrorTypeEnum.errorProduct "
                  v-model:value="record.box.exception.errorProduct.inWarehouseErrorCustomerType"
                  :placeholder="$t('common.please_select')"
                  style="width: 150px;"
                  @change="handleErrorDisposeChange(record)"
                >
                  <a-select-option 
                    v-for="(item,index) in inWarehouseErrorForErrorProductCustomerTypeEnum"
                    :key="index"
                    :value="item">
                    {{ $t($enumLangkey('inWarehouseErrorForErrorProductCustomerType', item)) }}
                  </a-select-option>
                  
                </a-select>
              </template>

            </template>
            <!-- 已操作的记录 -->
            <template v-else-if="record.box.exception.boxErrorType">
              <!-- 1 : 接收超时(漏发 和请求调查)-->
              <div v-if="record.box.exception.boxErrorType == inWarehouseOperationErrorTypeEnum.receiveTimeout">
                {{ $t($enumLangkey('productErrorDisposeType', record.box.exception.timeOut.inWarehouseErrorCustomerType)) }}
              </div>
              <!-- 2 : 错误产品 (销毁 和更改箱信息) -->
              <div v-if="record.box.exception.boxErrorType == inWarehouseOperationErrorTypeEnum.errorProduct">
                {{ $t($enumLangkey('inWarehouseErrorForErrorProductCustomerType', record.box.exception.errorProduct?.inWarehouseErrorCustomerType)) }}
               
              </div>
            </template>
            <span v-else>-</span>
          </template>
          <template #operationTitle>
            <a-button type="primary" ghost :loading="printLoadingId === 'allBox'" :disabled="!!printLoadingId" @click="handleBoxPrint()">{{ $t('common.bulk_print_box_label') }}</a-button>
          </template>
          <template #operation="{ record }">
            <a-button type="primary" ghost :loading="printLoadingId === record.detailId" :disabled="!!printLoadingId" @click="handleBoxPrint(record.detailId)">{{ $t('common.print_box_label') }}</a-button>
          </template>
          <template #title>
            <a-row :gutter="8" type="flex" justify="space-between">
              <a-col>
                <a-row :gutter="32" type="flex" align="middle">
                  <a-col>
                    {{ $t('warehouse.logistics_way') }}: {{ $t($enumLangkey('logisticsWay', pageState.logisticsType)) }}
                  </a-col>
                  <a-col>
                    {{ $t('warehouse.delivery_way') }}: {{ $t($enumLangkey('deliveryWay', pageState.deliveryType)) }}
                  </a-col>
                  <a-col v-if="pageState.isDeliver && pageState.isNeedPay">
                    <a-button type="primary" @click="handleOpenPayModal">{{ $t('finance.immediate_payment') }}</a-button>
                  </a-col>
                  <a-col>
                    <a-button type="primary" :disabled="!pageState.isDeliver" @click="handleOpenTrackModal">{{ $t('warehouse.tracking_shipment') }}</a-button>
                  </a-col>
                </a-row>
              </a-col>
              <a-col>
                <a-button type="link" @click="handleOpenProductModal">{{ $t('common.product_statistics') }}</a-button>
              </a-col>
            </a-row>
          </template>
          <template #footer>
            <a-row  type="flex" justify="space-between">
              <a-col :span="8">{{ $t('common.total') }}: {{ pageState.total ? pageState.total.plan.totalContainerBoxCount : '-' }}{{ $t('warehouse.box') }}</a-col>
              <a-col :span="8">
                <a-row :gutter="[16,0]">
                   <a-col>{{ pageState.total?.plan ? cmCubicToM(pageState.total.plan.totalVolume) : '-' }} m<sup>3</sup></a-col>
                   <a-col>{{ pageState.total?.plan ? gToKg(pageState.total.plan.totalWeight) : '-' }} kg</a-col>
                   <a-col>{{ pageState.total?.plan ? pageState.total.plan.totalWarehouseCount : '-' }} Unit</a-col>
                </a-row>
              </a-col>
              <a-col :span="8">
                 <a-row :gutter="[16,0]">
                  <a-col v-if="pageState?.total?.check?.totalVolume > 0">{{cmCubicToM(pageState.total.check.totalVolume)}} m<sup>3</sup></a-col>
                   <a-col v-if="pageState?.total?.check?.totalWeight > 0">{{ gToKg(pageState.total.check.totalWeight) }} kg</a-col>
                   <a-col v-if="pageState?.total?.check?.totalWarehouseCount > 0">{{ pageState.total.check.totalWarehouseCount}} Unit</a-col>
                </a-row>
              </a-col>
             
            </a-row>
          </template>
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :data-source="record.box.items"
              :pagination="false"
              :row-key="(record, index) => index"
              size="small"
            >
              <template #productInfo="{ record }">
                <a-row :gutter="8" type="flex" align="middle">
                  <a-col>
                    <div class="table-list-img-common">
                      <c-image
                        :src="record.item?.productImgUrl"
                        :thumbWidth="600"
                        :thumbHeight="600"
                      />
                    </div>
                  </a-col>
                  <a-col flex="1">
                    <div>{{ record.item.productName }}</div>
                    <div>{{ record.item.productNo }}</div>
                  </a-col>
                </a-row>
              </template>
              <template #encasementCount="{ record }">
                {{ record.item.containerCount }} Unit
              </template>
              <template #checkSize="{ record }">
                <span v-if="record.packing && record.packing?.checkContainerLength">
                  {{ record.packing?.checkContainerLength || '-' }}x{{ record.packing?.checkContainerWidth || '-' }}x{{ record.packing?.checkContainerHeight || '-' }}cm
                </span>
                <span v-else>-</span>
              </template>
              <template #checkWeight="{ record }">
                <span v-if="record.packing && record.packing.checkContainerWeight">{{ gToKg(record.packing?.checkContainerWeight) }}kg</span>
                <span v-else>-</span>
              </template>
              <template #checkCount="{ record }">
                <span v-if="record.packing?.checkCount">{{ record.packing?.checkCount}} Unit</span>
                <span v-else>-</span>
              </template>
              <template #checkImgUrls="{ record }">
                <a-popover v-if="record.packing?.checkImgUrls && record.packing.checkImgUrls.length > 0" :getPopupContainer="trigger => trigger.parentNode">
                  <template #content>
                    <a-space>
                      <div v-for="(imgItem, index) in record.packing.checkImgUrls"
                           class="table-list-img-common"
                           :key="index">
                        <c-image :src="imgItem" />
                      </div>
                    </a-space>
                  </template>
                  <span class="text-primary" style="font-size:20px">
                    <FileImageOutlined />
                  </span>
                </a-popover>
                <span v-else>-</span>
              </template>

              <template #errorLabelExplain="{ record }">
                <span v-if="record.labelFeeRemark">
                  {{$t('warehouse.labels_cannot_be_scanned_handling_expense_p0', [record.labelFeeRemark])}}
                </span>
              </template>
             
              <template #operation="{ record }">
                <a-button 
                  :disabled="!record.item.productLabelUrl"
                  type="primary" :loading="printProductLoading" ghost @click="handleProductLabelPrint(record.item)">{{ $t('warehouse.print_product_labels') }}</a-button>
              </template>
            </a-table>
          </template>
        </a-table>
        <CFee v-if="pageState.isNeedPay && fee" class="mt-5" :totalFee="fee.totalFee" :fees="fee.fees" />
        <CFee v-if="!pageState.isNeedPay && pageState.fee && pageState.fee.totalFee" class="mt-5" :totalFee="pageState.fee.totalFee" :fees="pageState.fee.fees" />
        <a-row class="mt-5 mb-3 mr-3" :gutter="16" type="flex" justify="end">
          <a-col v-if="pageState.isCanCancel"><a-button type="primary" ghost @click="handleCancel">{{ $t('common.cancel') }}</a-button></a-col>
          <a-col v-if="pageState.isShowOperationButton"><a-button type="primary" :loading="errorInvestigationLoading" @click="handleDoError">{{ $t('common.save') }}</a-button></a-col>
          <a-col><a-button type="primary" ghost @click="handleToList">{{ $t('warehouse.process_other_warehousing_plans') }}</a-button></a-col>
        </a-row>
      </a-spin>
      <Payment
        v-if="fee"
        ref="paymentRef"
        v-model:visible="payModalState.visible"
        :loading="payModalState.loading"
        :currencyId="fee.totalFee.currencyId"
        :orderNo="pageState.planNo"
        :unpaidSymbol="fee.totalFee.symbol"
        :unpaidSum="fee.totalFee.value"
        :payModalTitleType="9"
        @handlePayFee="handlePay"
      />
      <TrackingShipmentsModal ref="trackingShipmentsModalRef" />
      <ProductStatisticsModal ref="productStatisticsModalRef" />
      <ErrorInvestigationModal ref="errorInvestigationModalRef" @confirm="handleSetBoxRequestInvestigation" @cancel="handleCancelBoxRequestInvestigation" />
      <!-- <ReplaceProductNumberModal ref="replaceProductNumberModalRef" @confirm="handleConfirmReplaceProduct" @cancel="handleCancelReplaceProduct" /> -->

       <!-- 更换箱内信息 -->
      <a-modal
        width="800px"
        v-model:visible="updateProductModal.visible"
        :centered="true"
        @cancel="handleCancelUpdateProductModal"
        :maskClosable="false"
        :title="
          $t($enumLangkey('inWarehouseErrorForErrorProductCustomerType', inWarehouseErrorForErrorProductCustomerTypeEnum.updateBoxProductInfo)) +
          '(' +
          updateProductModal.record?.box?.boxNo +
          ')'
        "
        :footer="null"
      >
        <a-row :gutter="[12,12]">
          <a-col>
            <SearchProduct
              ref="refSearchProduct"
              v-model:productData="updateProductModal.productData"
            ></SearchProduct>
          </a-col>
          <a-col >
            <a-button
              type="primary"
              :disabled="!updateProductModal.productData"
              ghost
              @click="handleAdd"
              >{{ $t("common.add") }}</a-button
            >
          </a-col>
        </a-row>
        <a-table
          :columns="updateProductModalColumns"
          :data-source="updateProductModal.list"
          :scroll="{ x: 500, y: 600 }"
          :loading="updateProductModal.loading"
          size="small"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :pagination="false"
        >
          <template #product_info="{ record }">
            <a-space>
              <div class="table-list-img-common">
                <c-image :src="record?.productImgUrl" />
              </div>
              <div>
                <div>{{ record?.productName }}</div>
                <div>{{ record?.productNo }}</div>
              </div>
            </a-space>
          </template>

          <template #number="{ record }">
            <a-input-number
              v-model:value="record.containerCount"
              :precision="0"
              :min="1"
            ></a-input-number>
            Unit
          </template>

          <template #remove="{ record }">
            <div class="ant-form-inline">
              <a-button @click="handleUpdateProductModalRemove(record)">{{
                $t("common.remove")
              }}</a-button>
            </div>
          </template>
        </a-table>

        <a-row
          type="flex"
          justify="center"
          align="middle"
          class="mt-3"
          :gutter="[48, 12]"
        >
          <a-col>
            <a-button
              :loading="updateProductModal.loading"
              @click="handleCancelUpdateProductModal"
              >{{ $t("common.cancel") }}</a-button
            >
          </a-col>
          <a-col>
            <a-button
              :disabled="!updateProductModal.list||updateProductModal.list?.length == 0"
              :loading="updateProductModal.loading"
              type="primary"
              @click="handleConfirmUpdateProductModal"
              >{{ $t("common.save") }}</a-button
            >
          </a-col>
        </a-row>
      </a-modal>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, nextTick, reactive, ref, toRefs } from 'vue';
import {Typography,InputNumber, Button, Col, Input, Modal, Row, Select, Space, Spin, Popover, Table, Tag, message} from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CImage from '@/views/components/CImage.vue';
import CHaveProductInformation from '@/views/components/CHaveProductInformation.vue';
import CTagMixedOrSingle from '@/views/components/CTagMixedOrSingle.vue';
import UserDefinedBoxNo from '@/views/components/UserDefinedBoxNo.vue';
import CFee from '@/views/components/CFee.vue';
import Payment from '@/views/components/Payment.vue';
import SearchProduct from "@/views/components/SearchProduct.vue";
import CPlanNumberItem from '@/views/components/CPlanNumberItem.vue';
import TrackingShipmentsModal from './components/TrackingShipmentsModal.vue';
import ProductStatisticsModal from './components/ProductStatisticsModal.vue';
import ErrorInvestigationModal from './components/ErrorInvestigationModal.vue';
import ReplaceProductNumberModal from './components/ReplaceProductNumberModal.vue';
import { useI18n } from "vue-i18n/index";
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { gToKg, cmCubicToM, setFormStateValue, getAddressByLanguageV2, getLinkman } from '@/utils/general.js';
import { useTab } from "@/hooks/tabs/index";
import localPrint from "@/utils/localPrint";
import {
  logisticsWay as logisticsWayEnum,
  deliveryWay as deliveryWayEnum,
  inWarehouseStatus as inWarehouseStatusEnum,
  inWarehouseOperationErrorType as inWarehouseOperationErrorTypeEnum,
  productErrorDisposeType as productErrorDisposeTypeEnum,
  inWarehouseErrorForErrorProductCustomerType as inWarehouseErrorForErrorProductCustomerTypeEnum
} from '@/enum/enum.json';
import {
  getDelegate,
  getInWarehousePlanBoxPrint,
  getInWarehpusePlanBoxBatchPrint,
  getInWarehousePlanFee,
  payInWarehousePlan,
  updateCancelPlan,
  updateProductErrorStatus,
} from '@/api/modules/consignment/inbound.js';
import { cloneDeep } from "lodash-es";
import filters from "@/filters";

export default defineComponent({
  name: 'consignment_inbound_detail',
  components: {
    ATypographyText: Typography.Text,
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AButton: Button,
    ATable: Table,
    ASelect: Select,
    ASelectOption: Select.Option,
    ATag: Tag,
    APopover: Popover,
    ASpace: Space,
    AModal:Modal,
    AInputNumber: InputNumber,
    Content,
    CImage,
    CHaveProductInformation,
    CTagMixedOrSingle,
    UserDefinedBoxNo,
    CFee,
    SearchProduct,
    Payment,
    CPlanNumberItem,
    TrackingShipmentsModal,
    ProductStatisticsModal,
    ErrorInvestigationModal,
    ReplaceProductNumberModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();
    const { delVisitedRoute } = useTab();
    const { getters } = useStore();

    const paymentRef = ref(null);
    const trackingShipmentsModalRef = ref(null);
    const productStatisticsModalRef = ref(null);
    const errorInvestigationModalRef = ref(null);
    const replaceProductNumberModalRef = ref(null);

    const state = reactive({
      loading: false,
      pageState: {
        planId: null,
        planNo: null,
        planName: null,
        relation: null,
        toRelationPlanNo: null,
        creationTime: null,
        lastModificationTime: null,
        // 入库类型
        inWarehouseType: null,
        from: null,
        to: null,
        // 是否已经关联运输
        hasRelationTrans: false,
        // 是否可以取消
        isCanCancel: false,
        // 是否已经发运
        isDeliver: false,
        // 是否需要支付
        isNeedPay: false,
        // 是否有异常
        hasException: false,
        // 是否显示操作错误button
        isShowOperationButton: false,
        // 仓库类型
        warehouseType: null,
        // 总计
        total: null,
        // 费用
        fee: null,
        // 物流类型
        logisticsType: null,
        // 派送方式
        deliveryType: null,
        // 状态
        inWarehouseStatus: null,
      },
      // 费用
      fee: null,
      boxList: [],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        hideOnSinglePage: true,
        showSizeChanger: false,
        size: 'small',
        position: 'both',
      },
      // 打印loading Id
      printLoadingId: null,
      // 打印产品标
      printProductLoading: false,
      payModalState: {
        visible: false,
        loading: false,
      },
      // 异常处理加载
      errorInvestigationLoading: false,
    });

    const columns = [
      {
        width: 60,
        title: () => vueI18n.t("warehouse.serial_number"),
        slots: {
          customRender: "serialNumber"
        }
      },
      {
        width: 200,
        slots: {
          title: "boxNoTitle",
          customRender: "boxNo",
        }
      },
      { width: 50, },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.encasement_size"),
        slots: {
          customRender: "encasementSize"
        }
      },
      {
        width: 90,
        title: () => vueI18n.t("warehouse.encasement_weight"),
        slots: {
          customRender: "encasementWeight"
        }
      },
      {
        width: 140,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "encasementCount"
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.label_processing"),
        slots: {
          customRender: "labelDispose"
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.in_warehouse_status"),
        slots: {
          customRender: "inWarehouseStatus"
        }
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.in_warehouse_count"),
        slots: {
          customRender: "inWarehouseCount"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.error_state"),
        slots: {
          customRender: "errorState"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.need_operate"),
        slots: {
          customRender: "needOperate"
        }
      },
      {
        width: 150,
        slots: {
          title: "operationTitle",
          customRender: "operation"
        }
      },
    ];

    const innerColumns = [
      { width: 60, },
      {
        width: 350,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        }
      },
      {
        title: 'SESKU',
        dataIndex: "item[seSku]",
        width: 150,
      },
      {
        width: 100,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "encasementCount"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.approved_package_size"),
        slots: {
          customRender: "checkSize"
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.approved_package_weight"),
        slots: {
          customRender: "checkWeight"
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.in_warehouse_count"),
        slots: {
          customRender: "checkCount"
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.in_warehouse_img"),
        slots: {
          customRender: "checkImgUrls"
        }
      },
       {
        width: 120,
        title: () => '',
        slots: {
          customRender: "errorLabelExplain"
        }
      },
      {
        width: 150,
        slots: {
          customRender: "operation"
        }
      },
    ];

    const getBoxProductCount=(box)=>{
      let count = 0;
      box.items?.forEach(x=>{
        count = count + x.item.containerCount
      })
      return count
    }

    const getFullAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    }

    const handleOpenProductModal = () => {
      productStatisticsModalRef.value.open({ planId: state.pageState.planId, warehouseType: state.pageState.warehouseType });
    }

    // 取消
    const handleCancel = () => {
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("common.are_you_sure_cancel"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          const data = {
            planId: state.pageState.planId,
            warehouseType: state.pageState.warehouseType,
          }
          return updateCancelPlan(data).then(() => {
            message.success(vueI18n.t("common.succeed"));
            getDetail();
          });
        },
      });
    }

    // 取消请求调查弹窗处理结果
    const handleCancelBoxRequestInvestigation = ({ detailId }) => {
      state.boxList.forEach(item => {
        if (item.detailId === detailId) {
          item.box.exception.timeOut= {
            inWarehouseErrorCustomerType:null,
            requestInvestigation:{
              content:null,
              imgUrls:[],
            }
          };
        }
      });
    }

    // 请求调查弹窗处理结果
    const handleSetBoxRequestInvestigation = ({ detailId, requestInvestigation }) => {
      state.boxList.forEach(item => {
        if (item.detailId === detailId) {
          item.box.exception.timeOut.requestInvestigation = requestInvestigation;
        }
      });
    }

    // 下拉框操作
    const handleErrorDisposeChange = (record) => {
      let currentErrorType = record.box.exception.currentErrorType;
      if (currentErrorType == inWarehouseOperationErrorTypeEnum.receiveTimeout) {
        //接收超时的操作选择
        if (record.box.exception.timeOut.inWarehouseErrorCustomerType === productErrorDisposeTypeEnum.requestInvestigate) {
         let requestInvestigation = record.box.exception.timeOut.requestInvestigation;
          // 请求调查
          errorInvestigationModalRef.value.open(state.pageState, record.detailId, record.box.boxNo, record.trackNo, requestInvestigation);
        }
      } else if(currentErrorType == inWarehouseOperationErrorTypeEnum.errorProduct) {
        //错误商品的操作选择
        if (record.box.exception.errorProduct.inWarehouseErrorCustomerType === inWarehouseErrorForErrorProductCustomerTypeEnum.updateBoxProductInfo) {
          // 更改箱内信息
          handleShowUpdateProductModal(record);
        }
      }
      
    }

    // 验证items数据
    const validateErrorData = () => {
      //验证存在异常的是否都处理了
     return  state.boxList.findIndex(x=>{
            if (x.box.exception.currentErrorType) {
              if (x.box.exception.currentErrorType == inWarehouseOperationErrorTypeEnum.receiveTimeout
              &&
              !x.box.exception.timeOut.inWarehouseErrorCustomerType
              ) {
              return true 
              }

              if (x.box.exception.currentErrorType == inWarehouseOperationErrorTypeEnum.errorProduct
              &&
              !x.box.exception.errorProduct.inWarehouseErrorCustomerType
              ) {
              return true 
              }

              return false
            } else {
              return false
            }
          }) >= 0
     
    }

    const _getInParameter=()=>{
      let parameter=[];
      state.boxList.forEach(x=>{
        if (x.box.exception.currentErrorType == inWarehouseOperationErrorTypeEnum.receiveTimeout) {
          parameter.push({
            detailId:x.detailId,
            inWarehouseOperationErrorType:x.box.exception.currentErrorType,
            timeOut:{
              inWarehouseErrorCustomerType:x.box.exception.timeOut.inWarehouseErrorCustomerType,
              requestInvestigation:
              x.box.exception.timeOut.inWarehouseErrorCustomerType == productErrorDisposeTypeEnum.requestInvestigate ? x.box.exception.timeOut.requestInvestigation : null
            }
          })
        }

        if (x.box.exception.currentErrorType == inWarehouseOperationErrorTypeEnum.errorProduct ) {
           parameter.push({
            detailId:x.detailId,
            inWarehouseOperationErrorType:x.box.exception.currentErrorType,
            errorProduct:{
                inWarehouseErrorCustomerType:x.box.exception.errorProduct.inWarehouseErrorCustomerType,
                changeProduct: x.box.exception.errorProduct.inWarehouseErrorCustomerType == inWarehouseErrorForErrorProductCustomerTypeEnum.updateBoxProductInfo ? x.box.exception.errorProduct.changeProduct : null
            }
          })
        }
      })
      return parameter;
    }

    // 保存错误处理
    const handleDoError = () => {
      if (validateErrorData()) {
        message.error(vueI18n.t('warehouse.please_complete_all_exception_handling'))
        return;
      }

      const data = {
        planId: state.pageState.planId,
        warehouseType: state.pageState.warehouseType,
        items:_getInParameter(),
      }
      state.errorInvestigationLoading = true;
      updateProductErrorStatus(data).then(() => {
        message.success(vueI18n.t('common.succeed'));
        getDetail();
      }).catch(() => {}).finally(() => {
        state.errorInvestigationLoading = false;
      });
    }

    // 打印箱标
    const handleBoxPrint = async (detailId) => {
      try {
        const data = { id: state.pageState.planId }
        let printUrl = '';
        if (detailId) {
          Object.assign(data, { detailId });
          state.printLoadingId = detailId;
          printUrl = getInWarehousePlanBoxPrint(data);
        } else {
          state.printLoadingId = 'allBox';
          printUrl = getInWarehpusePlanBoxBatchPrint(data);
        }
        await localPrint(printUrl);
      } catch (error) {
      } finally {
        state.printLoadingId = null;
      }
    }

    // 打印产品标
    const handleProductLabelPrint = async (item) => {
      try {
        state.printProductLoading = true;
        await localPrint(item.productLabelUrl);
      } catch (error) {
      } finally {
        state.printProductLoading = false;
      }
    }

    // 打开追踪货件弹窗
    const handleOpenTrackModal = () => {
      const data = {
        planId: state.pageState.planId,
        warehouseType: state.pageState.warehouseType,
      }
      trackingShipmentsModalRef.value.open(data);
    }

    // 打开支付弹窗
    const handleOpenPayModal = () => {
      state.payModalState.visible = true;
      nextTick(() => {
        paymentRef.value.handleShowPayOrder();
      });
    }

    const handlePay = () => {
      state.payModalState.loading = true;
      const data = {
        planId: state.pageState.planId,
        payFee:filters.amountToFixed2(state.fee.totalFee.value),
        warehouseType: state.pageState.warehouseType,
      }
      payInWarehousePlan(data).then(() => {
        state.payModalState.visible = false;
        message.success(vueI18n.t('common.succeed'));
        getDetail();
      }).catch((err) => {
      }).finally(() => {
        state.payModalState.loading = false;
      });
    }

    const handleToList = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push('/consignment/inbound/list');
    }

    const getDetail = () => {
      state.loading = true;
      getDelegate({ planId: state.pageState.planId }).then(({ result }) => {
        setFormStateValue(state.pageState, result);
        state.boxList = result.items.map(item => {
          //用于记录 接收超时 处理的异常的数据=>保存时用到
          if (!item.box.exception.timeOut &&
            item.box.exception.currentErrorType == inWarehouseOperationErrorTypeEnum.receiveTimeout 
            ) {
            item.box.exception.timeOut = {
                inWarehouseErrorCustomerType:null,
                requestInvestigation:{
                  content:null,
                  imgUrls:[],
                }
              };
          }

          //用于记录 错误商品 处理的异常的数据=>保存时用到
          if (
            item.box.exception.currentErrorType == inWarehouseOperationErrorTypeEnum.errorProduct
          ) {
            item.box.exception.errorProduct.changeProduct = {
                items:[]
                //items:[{productId,containerCount}]
            };
          }

          item.box.items.map(ite => {
            ite.parentDetailId = item.detailId;
            let imgUrls = []
            if (Array.isArray(ite.packing?.checkImgUrls)) {
              imgUrls = imgUrls.concat(ite.packing.checkImgUrls);
            }
            
            if (!ite.packing) {
              ite.packing = {}
            }
            ite.packing.checkImgUrls = imgUrls;

            return ite;
          });

          return item;
        });
        if (state.pageState.isNeedPay) {
          getPlanFees();
        }
      }).catch(() => {}).finally(() => {
        state.loading = false;
      });
    }

    const getPlanFees = () => {
      const data = {
        planId: state.pageState.planId,
        warehouseType: state.pageState.warehouseType,
      }
      getInWarehousePlanFee(data).then(({ result }) => {
        if (result) {
          state.fee = result;
        }
      }).catch(() => {})
    }

    //--------------------------更换箱内信息 start---------------------------------------------------
    const updateProductModalColumns = [
      {
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "product_info",
        },
        width: 150,
      },

      {
        title: () => vueI18n.t("common.count"),
        slots: {
          customRender: "number",
        },
        width: 120,
      },

      {
        title: "",
        slots: {
          customRender: "remove",
        },
        fixed: "right",
        width: 80,
      },
    ];

    const updateProductModal = reactive({
      visible: false,
      loading: false,
      record: null,
      productData: null,
      list: [],
    });

    const refSearchProduct= ref();

    const handleShowUpdateProductModal = (record) => {
      updateProductModal.visible = true;
      updateProductModal.record = record;
      
      updateProductModal.productData = null;
      nextTick(()=>{
        refSearchProduct.value.searchProductKey = null;
        refSearchProduct.value.oldSearchKey = null;
      })
      
      // updateProductModal.loading = true;
      // getBoxOfProductsByWhboxId(record.box.whBoxId)
      //   .then(({ result }) => {
      //     updateProductModal.list = result;
      //     updateProductModal.loading = false;
      //   })
      //   .catch(() => {
      //     updateProductModal.loading = false;
      // });
      //不能查,只能从数据中取.
      updateProductModal.list=[];
      record.box.items.forEach(x=>{
        updateProductModal.list.push(cloneDeep(x.item));
      })
    };

    const handleAdd = () => {
      if (
        updateProductModal.list?.findIndex(
          x => x.productId == updateProductModal.productData.id
          ||
          x.id == updateProductModal.productData.id
          ) >= 0 
      ) {
        return message.error(vueI18n.t('warehouse.product_already_exists'))
      }
      updateProductModal.productData.productImgUrl=updateProductModal.productData.imgUrl;
      updateProductModal.productData.containerCount=null;
      updateProductModal.productData.productId = updateProductModal.productData.id;
      updateProductModal.list?.push(updateProductModal.productData);
      updateProductModal.productData = null;
      refSearchProduct.value.searchProductKey = null;
      refSearchProduct.value.oldSearchKey = null;
    };

    const handleUpdateProductModalRemove = (record) => {
      updateProductModal.list = updateProductModal.list.filter(
        (x) => x.productId != record.productId
      );
    };

    const handleCancelUpdateProductModal = () => {
      //  还原选择的下拉框
      updateProductModal.visible = false;
      updateProductModal.record.box.exception.errorProduct.inWarehouseErrorCustomerType= null;
    }

    const handleConfirmUpdateProductModal = () => {
      if (updateProductModal.list.find((x) => !x.containerCount)) {
        return message.error(
          vueI18n.t("common.p0_is_required", [vueI18n.t("common.count")])
        );
      }

      let items=[];//页面显示的时候用
      let items2=[];//保存的时候用
      updateProductModal.list.forEach(x=>{
        items.push({item:x});
        items2.push({
          productId: x.productId,
          containerCount: x.containerCount,
        });
      })
      updateProductModal.record.box.items = items;
      updateProductModal.record.box.exception.errorProduct.changeProduct.items = items2;
      updateProductModal.visible = false;
    };

    //--------------------------更换箱内信息 end---------------------------------------------------


    onMounted(() => {
      if ('id' in route.params) {
        let id = route.params.id;
        state.pageState.planId = id;
        getDetail();
      }
    });

    return {
      ...toRefs(state),
      gToKg,
      cmCubicToM,
      getLinkman,
      logisticsWayEnum,
      deliveryWayEnum,
      inWarehouseStatusEnum,
      inWarehouseOperationErrorTypeEnum,
      productErrorDisposeTypeEnum,
      inWarehouseErrorForErrorProductCustomerTypeEnum,
      paymentRef,
      trackingShipmentsModalRef,
      productStatisticsModalRef,
      errorInvestigationModalRef,
      replaceProductNumberModalRef,

      getBoxProductCount,

      columns,
      innerColumns,
      getFullAddress,
      handleOpenProductModal,
      handleCancel,
      handleCancelBoxRequestInvestigation,
      handleSetBoxRequestInvestigation,
      handleErrorDisposeChange,
      handleDoError,
      handleBoxPrint,
      handleProductLabelPrint,
      handleOpenTrackModal,
      handleOpenPayModal,
      handlePay,
      handleToList,

      //更换箱内信息
      refSearchProduct,
      updateProductModalColumns,
      updateProductModal,
      handleShowUpdateProductModal,
      handleAdd,
      handleUpdateProductModalRemove,
      handleConfirmUpdateProductModal,
      handleCancelUpdateProductModal,
    }
  }
})
</script>

<style scoped>

</style>